import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [input, setInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const storedChatHistory = localStorage.getItem("chatHistory");
    if (storedChatHistory) {
      setChatHistory(JSON.parse(storedChatHistory));
    } else {
      const initialMessage = {
        role: "bot",
        content: "DEGEN ICU ($dICU) is online. Ready to optimize your trades. Type in your query to begin.",
      };
      setChatHistory([initialMessage]);
    }
  }, []);

  useEffect(() => {
    if (chatHistory.length > 0) {
      localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
    }
  }, [chatHistory]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (input.trim() === "") return;

    const userPrompt = { role: "user", content: input };
    setChatHistory((prev) => [...prev, userPrompt]);
    setInput("");
    setIsLoading(true);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini",
          messages: [
            {
              role: "system",
              content:
                "You’re DEGEN ICU, the savvy, cutting-edge crypto analyst. You dissect trade data, crunch numbers, and give sharp, actionable insights to help users maximize profits. Stay focused, logical, and provide just enough info to make informed trading decisions. Your goal is to help users win trades and reduce losses.",
            },
            {
              role: "user",
              content: input,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const botResponse = {
        role: "bot",
        content: response.data.choices[0].message.content,
      };
      setIsLoading(false);
      setIsTyping(true);

      setTimeout(() => {
        displayTypingAnimation(botResponse);
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching from OpenAI:", error);
    }
  };

  const displayTypingAnimation = (botResponse) => {
    let index = 0;
    let currentMessage = "";

    const typingInterval = setInterval(() => {
      currentMessage += botResponse.content.charAt(index);
      setChatHistory((prev) => {
        const newHistory = [...prev];
        if (newHistory[newHistory.length - 1].role === "bot") {
          newHistory[newHistory.length - 1].content = currentMessage;
        } else {
          newHistory.push({ role: "bot", content: currentMessage });
        }
        return newHistory;
      });

      index++;
      if (index === botResponse.content.length) {
        clearInterval(typingInterval);
        setIsTyping(false);
      }
    }, 20);
  };

  const handleClearChat = () => {
    localStorage.removeItem("chatHistory");
    setChatHistory([]);
  };

  return (
    <div className="page-container">
      <header className="header">
        <h1>DEGEN ICU TERMINAL</h1>
        <p>Your degen analyst is online. Type in your trade question to start. $dICU CA: EGpx7QYgX21DbKuZXJbWxVrTDAVMeVknRAusoKkPpump</p>
      </header>

      <div className="terminal-container">
        <div className="chat-container">
          {chatHistory.map((message, index) => (
            <p key={index} className={`${message.role} fade-in`}>
              {message.role === "user" ? "> " : "DEGEN ICU: "}
              {message.content}
            </p>
          ))}
          {isLoading && <p className="loading">DEGEN ICU is calculating...</p>}
          {isTyping && <p className="typing">DEGEN ICU is typing...</p>}
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your query..."
            className="prompt-input"
          />
        </form>
      </div>

      <footer className="footer">
        <a href="https://t.me/DegenICU" target="_blank" rel="noopener noreferrer">
          Telegram
        </a>
        <a href="https://x.com/DegenICU" target="_blank" rel="noopener noreferrer">
          Twitter
        </a>
        <a href="https://pump.fun/EGpx7QYgX21DbKuZXJbWxVrTDAVMeVknRAusoKkPpump" target="_blank" rel="noopener noreferrer">
          PumpFun
        </a>
        <a href="#" className="clear-chat" onClick={handleClearChat}> 
          Clear Chat
        </a>
      </footer>
    </div>
  );
}

export default App;
